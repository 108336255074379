$primary: #2996b6;
$secondary: #f1f1f1;


.cities{

}

// EDIT CITIES
.editcities {
    .edit-form{
        margin-bottom: 30px;
    }
  .buttons-group {
    display: flex;
    justify-content: center;

    .img-part {
      width: 40px;
      height: 40px;
    }

    .showme:hover {
      .hidemee {
        display: inline;
      }
    }
    .hidemee {
      transition: 0.3s ease-in-out;
      display: none;
    }

    .btn-plus {
      border: none;
      // width: 10%;
      width: 40px;
      height: 40px;
      //margin-left: 20px;
      background: $primary;
      border-radius: 100%;
      padding: 10px;
      color: $secondary;
      font-size: 14px;
      transition: 0.8s ease-in-out;
      margin-top: 7px;
    }
  }

// .dion {
//   position: static !important;
// }

.test {
  margin-top: 100%;
}

}




