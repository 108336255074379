$primary: #2996b6;
$secondary: #f1f1f1;
$primary-shade: #6ECDE9;

.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 999;

  .validation-msg-modal {
    margin-top: 1rem;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #218838;
  }
}

.mymodal {
  z-index: 999;
  // position: relative;
  position: absolute;
  top: 15%;
  background: #fff;
  overflow: auto;
  // border: 1px solid rgb(197, 194, 194);
  border-radius: 5px;
  width: 50%;
  // height: 60%;
  height: auto;
  //margin-right: auto;
  //margin-left: auto;
  outline: none;
  display: flex;
  justify-content: center;

  

  .audio-player {
    margin-bottom: 12px;
    margin-left: 10%;
  }

  .validation-msg-modal {
    margin-top: 1rem;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #218838;
  }
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: rgba(0, 0, 0, 0.7) !important;
  
}

.modal-header{
  // background-color: #dedfe0;
  width: 100%;
  // margin-top: -43px ;
  // text-align: center;
  // justify-content: center;
  margin-bottom: 30px;
  // background-color: $primary;
  // color: #fff;
  // box-shadow: inset 0 -3em 3em rgba(66, 138, 245, 0.753),
  //       0 0  0 2px rgb(255,255,255),
  //       0.3em 0.3em 1em rgba(13, 94, 170, 0.3);
  color: #495057;
  // color: #fff;

  p{
    font-size: 22px;
    // margin: auto;
    font-weight: bold;
    padding: 0 0 0 75px;
    margin: auto 0;
  }
  button{
    background-color: transparent;
    border: none;
    font-weight: bold;
    color: #fff;
  }
}

.content-part {
  .close-btn{
    
  }
  .paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }

  .paginationBttns {
    a {
      padding: 10px;
      margin: 8px;
      border-radius: 5px;
      border: 1px solid $primary;
      color: $primary;
      cursor: pointer;
    }
  }

  .paginationBttns {
    a {
      &:hover {
        color: white;
        background-color: $primary;
      }
    }
  }

  .paginationActive {
    a {
      color: white;
      background-color: $primary;
    }
  }

  .paginationDisabled {
    a {
      display: none;
    }
  }

  h1 {
    display: flex;
    justify-content: center;
  }

  .confirm-button {
    //justify-content: center;
    //margin: auto;
  }

  //border: 3px solid red;
  justify-content: center;
  align-items: center;
  display: flex;
  //width:50%;
  button {
    //border: 3px solid red;
    //width: 100%;
    width: 100px;
    margin-right: 5px;
    margin-left: 5px;
  }
}

.audio-part {
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
  //align-content: center;
  align-items: center;
  
  .delete-modal{
    text-align: center;
  }

  form {
    width: 80%;
    margin: auto;
    .input-group-text{
      background-color: #2996b6 !important;
      color: #fff;
    }
    .form-modal{

      label{
        font-weight: bold;
        font-size: 12.5px;
      }
     
    }
  }
 
  .title-part {
    //border: 3px solid red;
    display: flex;
    align-items: center;
    align-content: center;
    .validation-msg-modal {
      margin-top: 1rem;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      color: #218838;
    }
  }
  .button-left{
    text-align: end;

    .submit-button {
      width: 35%;
      background-color: $primary;
      color: #fff;
      border: none;
      font-weight: 500 ;
      padding: 7px;
      
    }
  }
 

  .validation-msg-modal {
    margin-top: 1rem;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #218838;
  }

}

.points {
  .edit-point-button {
    border: none;
    background: $primary;
    color: white;
    margin-bottom: 30PX;
  }

  .image-part {
    margin-bottom: 5px;
    width: 100px;
    height: 100px;
    //object-fit: revert;
  }

  .validation-msg-modal {
    font-weight: bold;
    font-size: 14px;
    color: #218838;
  }

  .buttons-group {
    display: flex;
    justify-content: center;

    .img-part {
      width: 40px;
      height: 40px;
    }

    // .showme:hover {
    //   .hidemee {
    //     display: inline;
    //   }
    // }
    // .hidemee {
    //   transition: 0.3s ease-in-out;
    //   display: none;
    // }

    .btn-plus {
      border: none;
      // width: 10%;
      width: 40px;
      height: 40px;
      //margin-left: 20px;
      background: $primary;
      border-radius: 100%;
      padding: 10px;
      color: $secondary;
      font-size: 14px;
      transition: 0.8s ease-in-out;
      margin-top: 7px;
    }
  }

  .map-button {
    background: unset;
    padding: 12px;
    //transition: .3s ease-in-out;
    font-size: 28px;
    border: unset;
    margin-bottom: 5px;
    transition: 0.3s ease-in-out;

    .map-button-txt {
      margin-left: 20px;
      background: $primary-shade;
      border-radius: 10px;
      padding: 10px;
      color: $secondary;
      font-size: 18px;
      transition: 0.8s ease-in-out;
    }

    .fas {
      color: $primary;
    }

    &:hover,
    &:focus {
      i {
        color: $primary-shade;
      }
    }
  }

  .myButton {
    background-color: $primary;
    color: $secondary;
    border-radius: 5px;
    border: unset;
    padding: 10px;
    margin-bottom: 5px;
  }

  .listimi {
    width: 100%;
    background-color: $primary;
    color: $secondary;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 5px;

    a {
      color: $secondary;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

form {
  .text-error {
    color: maroon;
    font-weight: 600;
    font-size: 20px;
  }

  .text-validation {
    color: #218838;
    font-weight: 600;
    font-size: 20px;
  }

  .leaflet-container {
    height: 30vw;
    width: 70vw;
    //border: 3px solid red;
  }

  .image-form {
    margin-top: -2.5%;
    padding: 1.2%;
    background: white;
    border-radius: 0 0 20px 20px;

    button {
      float: right;

      &:disabled {
        background: #fff;
      }
    }
  }

  width: 50%;

  select {
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
  }

  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
  }

  .btn-plus {
    border: 3px solid red;
  }

  .map-save-button {
    position: absolute;
    z-index: 99999;
    top: 5%;
    right: 5%;
    padding: unset;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background: $primary;
    color: $secondary;
    border: unset;
    font-size: 30px;
    transition: 0.3s ease-in-out;

    &:hover {
      font-size: 35px;
    }
  }

  button {
    width: 50%;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
  }
}

//.myMap {
//    position: fixed;
//    top: 0;
//    left: 0;
//    bottom: 0;
//    right: 0;
//    z-index: 999;
//    background-color: rgba(0,0,0,.8);
//}
