$primary: #2996b6;
$primary-shade: #6ECDE9;
$secondary: #ffffff;

.App {
  // min-height: calc(1vw + 950px);
  overflow-x: hidden;
  // .sidebar {
  //     height: 100%;
  // }
  //   overflow-y: hidden;
  .row {
  }
  .mycontent {
    min-height: calc(1vw + 803px);
  }
  // .showme:hover {
  //   .hideme {
  //     display: inline;
  //   }
  // }
  // .hideme {
  //   display: none;
  //   transition: 0.3s ease-in-out;
  // }
  .page-title {
    background-color: $primary-shade;
    color: $secondary;
    width: 50%;
    margin-bottom: 20px;
    margin-left: -63px;
    padding-left: 20px;
    padding-top: 20px;
    border-radius: 0 50px 50px 0;
    @media only screen and (max-width: 780px) {
      width: fit-content;
   
      padding: 20px 20px 0px 20px;
 
    }
  }
  .listimi {
    transition: 0.3s ease-in-out;
    @media only screen and (max-width: 780px) {
      width: 100% !important;
    }
  }
  .listimi:hover {
    padding-left: 40px;
    background-color: $primary-shade;
    border-left: 15px solid $primary;
    margin-bottom: 10px;
    margin-top: 10px;
    color: white;
    font-weight: 900;
    .fa-edit {
      color: orange;
    }
    .fa-trash {
      color: orangered;
    }
  }
  form {
    @media only screen and (max-width: 780px) {
      width: 100% !important;
    }
  }

  .list-description{
    width: 100%;
    margin-left: 1px;
  }

  .paginationBttns {
        width: 80%;
        height: 40px;
        list-style: none;
        display: flex;
        justify-content: center;
    }

    .paginationBttns {
        a {
            padding: 10px;
            margin: 8px;
            border-radius: 5px;
            border: 1px solid $primary;
            color: $primary;
            cursor: pointer;
        }
    }

    .paginationBttns {
        a {
            &:hover {
                color: white;
                background-color: $primary;
            }
        }
    }

    .paginationActive {
        a {
            color: white;
            background-color: $primary;
        }
    }

    .paginationDisabled {
        a {
            display: none;
        }
    }
    .search-add{
      margin-top: 30px;
      margin-bottom: 10px;
      justify-content: space-between;
      .myButton {
        background-color: $primary;
        color: $secondary;
        border-radius: 5px;
        border: unset;
        padding: 10px;
        margin-bottom: 5px;
        
      }
    }
    .listimi{
        width: 100%;
        background-color:  $primary;
        color: $secondary;
        margin-bottom: 5px;
        padding: 10px;
        border-radius: 5px;
        a {
            color: $secondary;
            &:hover{
                text-decoration: none;
            }
        }
    }
}

form {
    width: 50%;
    select {
        width: 100%; 
        padding: 10px;
        margin-bottom: 5px;
        border-radius: 5px;
    }
    input {
        width: 100%; 
        padding: 10px;
        margin-bottom: 5px;
        border-radius: 5px;
    }
    button {
        width: 50%; 
        padding: 10px;
        margin-bottom: 5px;
        border-radius: 5px;
    }




}

.dion > div{
  position: absolute!important;
  // width: 60%;
}


.buttonAction{
  background-color: #2996b6;
  color: #f1f1f1;
  border-radius: 5px;
  border: unset;
  padding: 10px;
  margin-bottom: 5px;
  width: 100%;
}

.tableStyle{
  /* width: 100%;
  margin-bottom: 1rem; */
  color: #212529;
  background: #e2e2e2;
  border-radius: 12px;
  /* color: white; */
  font-weight: 600;
  border: 3px solid #6ECDE9;
  padding: 8px;
.listimi{
  &:hover{

    // padding-left: unset;

    background-color: #6ECDE9;
    border-left: unset;
    margin-bottom: 10px;
    margin-top: 10px;
    color: black;
     font-weight: unset; 
    padding: 10px;
  }

}

}
.eventImage {
  position: absolute;
  top: 5%;
  left: 30%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;

@media screen and (max-width: 767px) {

    width: 80px;
    height: 80px;
    top: 3%;

}
@media screen and (max-width: 479px) {

    width: 60px;
    height: 60px;
    left: 23%;
    top: 1%;
  
}
}