$primary: #2996b6;
$secondary: #f1f1f1;

.points {



  .leaflet-container {
    height: 30vw;
    width: 70vw;
    //border: 3px solid red;
  }
 
}