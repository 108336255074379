$primary: #2996b6;
$secondary: #f1f1f1;
$primary-shade: #6ECDE9;

.transactions {
    .headeri {
        width: 100%;
        background-color: $primary-shade;
        color: $secondary;
        padding: 5px;
    }
    .listimet {
        width: 100%;
        &:hover {
            padding: unset;
            background-color: inherit;
            color: inherit;
            border: unset;
            font-size: unset;
            font-weight: unset;
            margin: unset;
            // border-bottom: 2px grey solid;

        }
    }
}