$primary: #2996b6;
$secondary: #f1f1f1;

.footer {
 background-color: salmon;
 width: 100%;
 margin-left: -15px;
 margin-right: 25px;
 padding: 20px;
    .footer_links {
        ul {
            display: block;
            list-style: none;
            text-align: center;
            color: $primary;
            li {
                display: inline-block;
                padding: 20px;
                cursor: pointer;
                &:hover {
                  opacity: .8;   
                }
            }
        }
    }
}
