$primary: #2996b6;
$secondary: #f1f1f1;

.users {
  .delete-user-button{
    border: none;
    background: $primary;
    color: white;
    margin-bottom: 30px;
  }
}