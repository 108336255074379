$primary: #6ecde9;
$secondary: #ffffff;

.header {
  .navbar {
    display: none;
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // height: 7%;
    background: $primary;
    color: $secondary;
    padding: 20px 20px;
  }

  @media only screen and (max-width: 780px) {
    .navbar {
      display: flex;

      .menuitem {
        padding-left: 0;

        svg {
          float: right;
          margin-right: 100px;
          margin-top: -30px;
          font-size: 20px;
        }

        &:hover,
        &:focus {
          color: $primary;
        }
      }

      ul {
        li,
        a {
          padding: 10px 0px;
          color: $secondary;
          transition: 0.3s ease-in-out;
          list-style: none;
        }

        li:hover,
        li:focus {
          background-color: $secondary;
          color: $primary;
          border-radius: 20px 0 0 20px;

          padding-left: 60px;
          margin-left: -40px;

          a {
            color: $primary;
            text-decoration: unset;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.sidebar {
  background-color: $primary;
  color: $secondary;
  height: 100vh;
  overflow-y: auto;
  border-right: 2px solid #f1f1f1;
  position: fixed;
  top: 50px;
  transition: 1s linear;
  padding-left: 4rem;
  padding-top: 2rem;
  padding-left: 3rem;
  top: 0;
  left: 0;
  bottom: 0;
  width: 24.7%;
  // min-height: 800px;
  .logo {
    min-width: 100px;
    max-width: 250px;
    margin-top: 10%;
    margin-bottom: 35px ;
  }
  ul {
    list-style: none;
    text-align: left;

    .active-sidebar {
      background-color: $secondary !important;
      color: $primary !important;
      border-radius: 20px 0 0 20px;

      padding-left: 60px;
      margin-left: -40px;
      margin-top: 3px;

      a {
        color: $primary;
        text-decoration: unset;
        cursor: pointer;
      }
    }

    .inactive {
      background-color: $primary !important;

      a {
        color: $secondary !important;
      }
    }

    li,
    a {
      padding: 10px 0px;
      color: $secondary;
      transition: 0.3s ease-in-out;
    }

    li:hover,
    li:focus {
      background-color: $secondary;
      color: $primary;
      border-radius: 20px 0 0 20px;
      padding-left: 60px;
      margin-left: -40px;

      a {
        color: $primary;
        text-decoration: unset;
        cursor: pointer;
      }
    }

    .active_link :focus {
      font-weight: bold;
      // font-size: 17px;
      background-color: $secondary;
      color: $primary;
      border-radius: 20px 20px 20px 20px;
      padding-left: 60px;
      padding-right: 60px;
      margin-left: -40px;
    }

    .active_link1 :focus {
      font-weight: bold;
      // font-size: 17px;
      background-color: $secondary;
      color: $primary;
      border-radius: 20px 20px 20px 20px;
      padding-left: 50px;
      padding-right: 50px;
      margin-left: -40px;

    }

    .menuitem {
      padding-left: 0;

      svg {
        // float: right;
        position: absolute;
        right: 5% !important;
        // margin-right: 90px;
        // margin-top: -30px;
        font-size: 20px;
      }

      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }

  @media only screen and (max-width: 780px) {
    display: none;
  }
}
