$primary: #2996b6;
$secondary: #f1f1f1;

.login {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom:0;
    background-color: $primary;
    text-align: center;
    .login-logo {
        padding-top:10%;
    }
    .login-wraper {
        position: inline;
        margin: auto;
        width: 30%;
        // border: $secondary solid 5px;
        border-left: $secondary solid 5px;
        border-radius:0 20px 20px 0;
        padding: 20px;
        margin-top: 10%;
        box-shadow: 10px 10px 15px rgba(0, 0, 0, .2);
        transition: .3s ease-in-out;
        &:hover, &:focus {
            border-left: $secondary solid 25px;
            box-shadow: 15px 15px 25px rgba(0, 0, 0, .2);
        }
        form {
            width: 100%;
            .input-field {
                i {
                    float: right;
                    position: relative;
                    // right: 38%;
                    margin-top: -35px;
                    margin-right: 20px;
                }
            }

            button {
                background-color: $primary;
                border: $secondary 2px solid;
                border-radius: 10px;
                box-decoration-break: unset;
                transition: .3s ease-in-out;
                color:$secondary;
                &:hover, &:focus{
                    // border: $secondary 5px solid;
                    width: 100%;
                }
            }
            input {
                background-color: $primary;
                color: $secondary;
                border: $secondary 2px solid;
               
            }
            input::placeholder {
                color: $secondary !important;
            }
            .password-icon{
                position: relative;
                // top: 540px;
                // right: 710px;
                cursor: pointer;
            }
            .eye {
                background-color: transparent;
                width: 30px;
            }
            .fa {
                color: $secondary;
            }
        }


        @media only screen and (max-width: 780px) {
            // border: 3px solid red;
            width:90% !important;
            margin-top: 30%;
          }
        
    }
}